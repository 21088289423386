import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from "reactstrap";


import {Description, Name} from "./apartmentRoomCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WarningModal from "components/Common/WarningModal";

import {
    addNewApartmentRoom as onAddNewApartmentRoom,
    deleteApartmentRoom as onDeleteApartmentRoom,
    getApartmentRooms as onGetApartmentRooms,
    updateApartmentRoom as onUpdateApartmentRoom
} from "store/apartmentRoom/actions";

//redux
import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import withRouter from "../../hooks/withRouter";
import Notification from "../../components/Notification";

const ApartmentRoom = props => {

    //meta title
    document.title = `CRM | ${props.t("Apartment Rooms")}`;

    const initialApartmentRoom = {
        numberOfRoom: "",
    }

    const dispatch = useDispatch();
    const [deleteApartmentRoom, setDeleteApartmentRoom] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [apartmentRoom, setApartmentRoom] = useState(initialApartmentRoom);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");

    const {apartmentRooms, error} = useSelector(state => ({
        apartmentRooms: state.ApartmentRoom.apartmentRooms,
        error: state.ApartmentRoom.error
    }));

    useEffect(() => {
        dispatch(onGetApartmentRooms());
    }, []);


    const handleRefresh = () => {
        dispatch(onGetApartmentRooms());
    };

    const columns = useMemo(
        () => [
            {
                HeaderLabel: `${props.t("Number Of Room")}`,
                accessor: "numberOfRoom",
                filterable: true,
                Cell: cellProps => {
                    return <Name {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Action")}`,
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-warning"
                                onClick={() => {
                                    setApartmentRoom(cellProps.row.original),
                                        handleApartmentRoomClick(cellProps.row);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip"/>
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    {props.t("Edit")}
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const apartmentRoomData = cellProps.row.original;
                                    onClickDelete(apartmentRoomData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip"/>
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    {props.t("Delete")}
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            }
        ],
        []
    );

    const toggle = () => {
        if (modal) {
            setApartmentRoom(initialApartmentRoom);
            setModal(false);
            setIsEdit(false);
        } else {
            setModal(true);
        }
    };

    const handleApartmentRoomClick = arg => {
        const apartmentRoom = arg.original;
        setIsEdit(true);
        setApartmentRoom({
            blockName: apartmentRoom.blockName,
            numberOfApartments: apartmentRoom.numberOfApartments,
            address: apartmentRoom.address,
            constructionYear: apartmentRoom.address
        });
        toggle();
    };

    //delete lead
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = apartmentRoom => {
        setDeleteApartmentRoom(apartmentRoom);
        setDeleteModal(true);
    };

    const handleDeleteApartmentRoom = () => {
        dispatch(onDeleteApartmentRoom(deleteApartmentRoom.id));
        setDeleteModal(false);
    };

    const handleChange = (e) => {
        setApartmentRoom({
            ...apartmentRoom,
            [e.target.name]: e.target.value
        });
    };

    const handleClose = () => {
        setMessage("");
        setShowNotification(false);
    };

    const saveApartmentRoom = () => {
        if (isEdit) {
            dispatch(onUpdateApartmentRoom(apartmentRoom));
        } else {
            dispatch(onAddNewApartmentRoom(apartmentRoom));
        }
        toggle();
    };

    useEffect(() => {
        if (Object.entries(error).length > 0) {
            setMessage(error.message);
            setShowNotification(true);
            setNotificationType("Danger");
        }
    }, [error]);

    return (
        <React.Fragment>
            <WarningModal
                show={deleteModal}
                onApproveClick={handleDeleteApartmentRoom}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Notification
                message={message}
                show={showNotification}
                callbackShow={handleClose}
                type={notificationType}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={props.t("ApartmentRooms")}/>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        refreshButton={true}
                                        handleRefreshClick={handleRefresh}
                                        columns={columns}
                                        data={apartmentRooms}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        handleAddClick={toggle}
                                        customPageSize={25}
                                        className="custom-header-css"
                                    />
                                    <Modal isOpen={modal} toggle={toggle}>
                                        <ModalHeader toggle={toggle} tag="h4">
                                            {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                                        </ModalHeader>
                                        <ModalBody>
                                            <Row form="true">
                                                <Col xs={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("Number Of Room")}</Label>
                                                        <Input
                                                            name="numberOfRoom"
                                                            type="text"
                                                            onChange={handleChange}
                                                            value={apartmentRoom.numberOfRoom || ""}
                                                            invalid={!apartmentRoom.numberOfRoom}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="text-end">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success save-apartmentRoom"
                                                            onClick={saveApartmentRoom}
                                                        >
                                                            {props.t("Save")}
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(ApartmentRoom));
