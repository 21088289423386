//REGISTER
export const REGISTER = "/auth/signup";

//AUTH
export const LOGIN = "/auth/signin";
export const LOGOUT = "/auth/logout";
export const FB_LOGIN = "/oauth2/facebook";
export const GET_LOGIN_ATTEMPTS = "/authLog/getAll/0/20";
export const GET_USER_LOGIN_ATTEMPTS = "/authLog/getByLoggedInUser";
export const GET_AUTH_LOGS = "/authLog/findByParameters";

export const GET_FACEBOOK_AUTH_LOG = "/facebookIntegrationLog/getAll";

//PROFILE
export const EDIT_PROFILE = "/edit_profile";

//LEADS
export const GET_LEADS = "/lead/getAll";
export const ADD_NEW_LEAD = "/lead";
export const UPDATE_LEAD = "/lead";
export const DELETE_LEAD = "/lead";
export const GET_LEAD_BY_ID = "/lead";
export const SAVE_IMAGES = "/photo/byLead";
export const GET_PHOTOS = "/photo/getByLeadId";
//setSalesRepresentative
export const SET_REPRESENTATIVE = "/lead/assignSalesRepresentative";
export const SET_SEGMENT = "/lead/assignSegment";
export const TRANSFER_DATA = "/leadIntegration/sendToCRM";

//SEGMENTS
export const GET_SEGMENTS = "/segment/getAll";
export const ADD_NEW_SEGMENT = "/segment";
export const UPDATE_SEGMENT = "/segment";
export const DELETE_SEGMENT = "/segment";

//RESOURCES
export const GET_RESOURCES = "/resource/getAll";
export const ADD_NEW_RESOURCE = "/resource";
export const UPDATE_RESOURCE = "/resource";
export const DELETE_RESOURCE = "/resource";

//CATEGORIES
export const GET_CATEGORIES = "/category/getAll";
export const ADD_NEW_CATEGORY = "/category";
export const UPDATE_CATEGORY = "/category";
export const DELETE_CATEGORY = "/category";

//USERS
export const GET_USERS = "/users/getAll";
export const GET_USER_PROFILE = "/users";
export const ADD_NEW_USER = "/users";
export const UPDATE_USER = "/users";
export const DELETE_USER = "/users";
export const GET_USERS_BY_ROLE = "/users/getAllByRole";
export const CHANGE_PASSWORD = "/users/changePassword";
export const RESET_PASSWORD = "/users/createPassword";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

//ROLES
export const GET_ROLES = "/roles/getAll";
export const ADD_NEW_ROLE = "/roles";
export const UPDATE_ROLE = "/roles";
export const DELETE_ROLE = "/roles";

// NOTES
export const GET_NOTES = "/note/getAll";
export const ADD_NEW_NOTE = "/note";
export const UPDATE_NOTE = "/note";
export const DELETE_NOTE = "/note";
export const GET_LEAD_NOTES = "/note/getByLeadId";

// IMPORT
export const IMPORT_EXCEL = "/fileImport/excel";
export const IMPORT_CSV = "/fileImport/csv";

//REFERENCES
export const GET_REFERENCES = "/reference/getAll";
export const ADD_NEW_REFERENCE = "/reference";
export const UPDATE_REFERENCE = "/reference";
export const DELETE_REFERENCE = "/reference";

// APPOINTMENTS
export const GET_APPOINTMENTS = "/appointment/getAll";
export const ADD_NEW_APPOINTMENT = "/appointment";
export const UPDATE_APPOINTMENT = "/appointment";
export const DELETE_APPOINTMENT = "/appointment";
export const GET_LEAD_APPOINTMENT = "/appointment/getByLeadId";
export const GET_USER_APPOINTMENTS = "/appointment/getByCreateUserId";
export const GET_TODAY_APPOINTMENT = "/appointment/getTodaysAppointments";

// CRITERIA
export const GET_LEADS_CRITERIA_WITH_SEGMENT = "/lead/getCriteria";
export const LEADS_CRITERIA = "/lead/findByParameters";
export const USER_CRITERIA = "/users/findByParameters";

// REPORT
export const GET_LEAD_REPORTS_BY_USER = "/report/leadReportByUser";
export const GET_LEAD_COUNTS_BY_SEGMENT = "/report/leadCountsReportBySegment";
export const GET_LEAD_COUNTS_BY_RESOURCE = "/report/leadCountsReportByResource";
export const LEAD_REPORTS_BY_DATE_CRITERIA = "/report/leadReportByCreateDate";

//LANGUAGE
export const GET_LANGUAGES = "/language/getAll";
export const ADD_NEW_LANGUAGE = "/language";
export const UPDATE_LANGUAGE = "/language";
export const DELETE_LANGUAGE = "/language";

//Integration Definition
export const GET_FORMS = "/facebookIntegrationDefinition/getAll";
export const ADD_NEW_FORM = "/facebookIntegrationDefinition";
export const UPDATE_FORM = "/facebookIntegrationDefinition";
export const DELETE_FORM = "/facebookIntegrationDefinition";

//Facebook Form
export const GET_FACEBOOK_FORMS = "/facebookForm/getAll";
export const ADD_NEW_FACEBOOK_FORM = "/facebookForm";
export const UPDATE_FACEBOOK_FORM = "/facebookForm";
export const DELETE_FACEBOOK_FORM = "/facebookForm";

//platform
export const GET_PLATFORMS = "/platform/getAll";
export const ADD_NEW_PLATFORM = "/platform";
export const UPDATE_PLATFORM = "/platform";
export const DELETE_PLATFORM = "/platform";

// REMINDERS
export const GET_REMINDERS = "/reminder/getAll";
export const ADD_NEW_REMINDER = "/reminder";
export const UPDATE_REMINDER = "/reminder";
export const DELETE_REMINDER = "/reminder";
export const GET_LEAD_REMINDER = "/reminder/getByLeadId";
export const GET_USER_REMINDERS = "/reminder/getByCreateUserId";
export const GET_TODAY_REMINDER = "/reminder/getTodaysReminders";

//REMINDER CRITERIA
export const REMINDER_CRITERIA = "/reminder/findByParameters";

//APPOINTMENT CRITERIA
export const APPOINTMENT_CRITERIA = "/appointment/findByParameters";

//Notificaitons
export const GET_NOTIFICATION_COUNT = "/reminder/getCurrentUserTodaysRemindersCount";

//PARAMETER
export const GET_PARAMETERS = "/systemParameter/getAll";
export const ADD_NEW_PARAMETER = "/systemParameter";
export const UPDATE_PARAMETER = "/systemParameter";
export const DELETE_PARAMETER = "/systemParameter";

export const SYSTEM_PARAMETER_BY_CODE = "/systemParameter/findByCode";
export const GET_ACTIVE_USER_COUNT = "/users/findByParametersCount";

//LEAD HISTORY
export const LEAD_HISTORY = "/leadHistory/getAll";
export const LEAD_HISTORY_BY_ENTITY_ID = "/leadHistory/entityId";
export const LEAD_HISTORY_PAGEABLE = "/leadHistory/getAll";
export const LEAD_HISTORY_BY_FILTER = "/leadHistory/getByFilter";

//APPOINTMENT HISTORY
export const APPOINTMENT_HISTORY = "/appointmentHistory/getAll";
export const APPOINTMENT_HISTORY_BY_ENTITY_ID = "/appointmentHistory/entityId";
export const APPOINTMENT_HISTORY_PAGEABLE = "/appointmentHistory/getAll";
export const APPOINTMENT_HISTORY_BY_FILTER = "/appointmentHistory/getByFilter";

//REMINDER HISTORY
export const REMINDER_HISTORY = "/reminderHistory/getAll";
export const REMINDER_HISTORY_BY_ENTITY_ID = "/reminderHistory/entityId";
export const REMINDER_HISTORY_PAGEABLE = "/reminderHistory/getAll";
export const REMINDER_HISTORY_BY_FILTER = "/reminderHistory/getByFilter";

//NOTE HISTORY
export const NOTE_HISTORY = "/noteHistory/getAll";
export const NOTE_HISTORY_BY_ENTITY_ID = "/noteHistory/entityId";
export const NOTE_HISTORY_PAGEABLE = "/noteHistory/getAll";
export const NOTE_HISTORY_BY_FILTER = "/noteHistory/getByFilter";

//TERMS AND POLICY
export const GET_TERMS_INFO = "/userContract/userId";
export const GET_PRIVACY_INFO = "/userContract/userId";
export const POST_TERMS_INFO = "/userContract";
export const POST_PRIVACY_INFO = "/userContract";
export const SAVE_CONTRACTS = "/userContract/saveAll";

//CONTRACTS
export const LATEST_CONTRACTS = "/contract/getLatestContracts";

export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CALENDAR_CATEGORIES = "/categories";

//LEAD_INTEGRATION_DEFINITION
export const LEAD_INT_DEF = "/leadIntegrationDefinition";

//DOCTOR
export const GET_DOCTORS = "/doctor/getAll";
export const ADD_NEW_DOCTOR = "/doctor";
export const UPDATE_DOCTOR = "/doctor";
export const DELETE_DOCTOR = "/doctor";

//HOSPITAL
export const GET_HOSPITALS = "/hospital/getAll";
export const ADD_NEW_HOSPITAL = "/hospital";
export const UPDATE_HOSPITAL = "/hospital";
export const DELETE_HOSPITAL = "/hospital";

//Operation Definition
export const GET_OPERATION_DEFINITIONS = "/operationDefinition/getAll";
export const ADD_NEW_OPERATION_DEFINITION = "/operationDefinition";
export const UPDATE_OPERATION_DEFINITION = "/operationDefinition";
export const DELETE_OPERATION_DEFINITION = "/operationDefinition";

//Operation Category
export const GET_OPERATION_CATEGORY = "/operationCategory/getAll";
export const ADD_NEW_OPERATION_CATEGORY = "/operationCategory";
export const UPDATE_OPERATION_CATEGORY = "/operationCategory";
export const DELETE_OPERATION_CATEGORY = "/operationCategory";

//Operation
export const GET_MEDICAL_OPERATION = "/medicalOperation/getAll";
export const ADD_NEW_MEDICAL_OPERATION = "/medicalOperation";
export const UPDATE_MEDICAL_OPERATION = "/medicalOperation";
export const DELETE_MEDICAL_OPERATION = "/medicalOperation";

//Offer Items
export const OFFER_ITEM_TEMPLATES = "/offerItem/findTemplates";
export const GET_OFFER_ITEMS = "/offerItem/getAll";
export const ADD_NEW_OFFER_ITEM = "/offerItem";
export const UPDATE_OFFER_ITEM = "/offerItem";
export const DELETE_OFFER_ITEM = "/offerItem";

//Offer Items
export const OFFER_TEMPLATES = "/offer/findTemplates";
export const GET_OFFERS = "/offer/getAll";
export const ADD_NEW_OFFER = "/offer";
export const UPDATE_OFFER = "/offer";
export const DELETE_OFFER = "/offer";

// ADD_NEW_DESCRIPTION_TEMPLATE
export const GET_DESCRIPTION_TEMPLATES = "/descriptionTemplate/getAll";
export const ADD_NEW_DESCRIPTION_TEMPLATE = "/descriptionTemplate";
export const UPDATE_DESCRIPTION_TEMPLATE = "/descriptionTemplate";
export const DELETE_DESCRIPTION_TEMPLATE = "/descriptionTemplate";

//Firm Info
export const GET_FIRM_INFO = "/firmInfo/getAll";
export const ADD_NEW_FIRM_INFO = "/firmInfo";
export const UPDATE_FIRM_INFO = "/firmInfo";
export const DELETE_FIRM_INFO = "/firmInfo";

// SCREEN
export const GET_SCREENS = "/screen/getAll";
export const ADD_NEW_SCREEN = "/screen";
export const UPDATE_SCREEN = "/screen";
export const DELETE_SCREEN = "/screen";

// SCREEN ACTION
export const GET_SCREEN_ACTIONS = "/screenAction/getAll";
export const ADD_NEW_SCREEN_ACTION = "/screenAction";
export const UPDATE_SCREEN_ACTION = "/screenAction";
export const DELETE_SCREEN_ACTION = "/screenAction";

//GROUP
export const GET_GROUPS = "/userGroup/getAll";
export const GROUP_API = "/userGroup";
export const FIND_DOCTORS_WITH_GROUPS = "/userGroup/findDoctorWithGroups";

//PATIENTS
export const GET_PATIENTS = "/patient/getAll";
export const ADD_NEW_PATIENT = "/patient";
export const SAVE_WITH_APPT = "/patient/saveWithAppointment";
export const UPDATE_PATIENT = "/patient";
export const DELETE_PATIENT = "/patient";
export const GET_PATIENT_BY_ID = "/patient";
export const SAVE_PATIENT_IMAGES = "/patientPhoto/byPatient";
export const GET_PATIENT_PHOTOS = "/photo/getByPatientId";

export const GET_PATIENT_APPOINTMENTS = "/patientAppointment/getAll";
export const ADD_NEW_PATIENT_APPOINTMENT = "/patientAppointment";
export const UPDATE_PATIENT_APPOINTMENT = "/patientAppointment";
export const DELETE_PATIENT_APPOINTMENT = "/patientAppointment";
export const GET_USER_PATIENT_APPOINTMENTS = "/patientAppointment/getByCreateUserId";
export const GET_PATIENT_APPOINTMENT = "/patientAppointment/getByPatientId";
export const GET_PATIENT_APPOINTMENT_CRITERIA = "/patientAppointment/findByParameters";

export const GET_PATIENT_NOTES = "/patientNote/getAll";
export const ADD_NEW_PATIENT_NOTE = "/patientNote";
export const UPDATE_PATIENT_NOTE = "/patientNote";
export const DELETE_PATIENT_NOTE = "/patientNote";
export const GET_PATIENT_NOTE = "/patientNote/getByPatientId";

export const GET_PATIENT_REMINDERS = "/patientReminder/getAll";
export const ADD_NEW_PATIENT_REMINDER = "/patientReminder";
export const UPDATE_PATIENT_REMINDER = "/patientReminder";
export const DELETE_PATIENT_REMINDER = "/patientReminder";
export const GET_USER_PATIENT_REMINDERS = "/patientReminder/getByCreateUserId";
export const GET_PATIENT_REMINDER = "/patientReminder/getByPatientId";
export const GET_PATIENT_REMINDER_CRITERIA = "/patientReminder/findByParameters";

export const SET_DOCTOR = "/patient/assignDoctor";
export const PATIENTS_CRITERIA = "/patient/findByParameters";

export const DEPARTMENT_API = "/department";
export const DEPARTMENT_GET_ALL = "/department/getAll";
export const WORK_SCHEDULE_API = "/userWorkSchedule";
export const WORK_SCHEDULE_API_BY_ID = "/userWorkSchedule/getByUserId";
export const AVAILABILTY_API = "/userAvailability";
export const AVAILABILTY_API_BY_ID = "/userAvailability/getByUserId";
//PAYMENT
export const UNPAID_PAYMENTS = "/payment/getUnpaidPayments";
export const UPDATE_USER_COUNT_PRICE = "/payment/updateUserCountAndPrice";
export const PAYMENT_PROCESS_URL = "/payment/paymentProcess";
export const GET_PAYMENTS = "/payment/getAll";
export const ADD_NEW_PAYMENT = "/payment";
export const UPDATE_PAYMENT = "/payment";
export const DELETE_PAYMENT = "/payment";
export const CREATE_PAYMENT = "/payment/createAnnualPayments";
export const ADD_USER_PAYMENT = "/payment/createAddUserPayment";

export const SECTION_API = "/section";
export const SECTION_GET_ALL = "/section/getAll";
export const FIND_USERS_SAME_GROUP ="/users/findUsersInSameGroup"

export const BUILDING_GET_ALL = "/building/getAll";
export const BUILDING_API = "/building";
export const BUILDING_CRITERIA ="/building/findByParameters"

export const APARTMENT_GET_ALL = "/apartment/getAll";
export const APARTMENT_API = "/apartment";
export const APARTMENT_CRITERIA ="/apartment/findByParameters"
export const APARTMENT_FIND_FILTERS ="/apartment/findFilters"

export const APARTMENT_ROOM_GET_ALL = "/apartmentRoom/getAll";
export const APARTMENT_ROOM_API = "/apartmentRoom";
export const APARTMENT_ROOM_CRITERIA ="/apartmentRoom/findByParameters"

export const CUSTOMER_GET_ALL = "/customer/getAll";
export const CUSTOMER_API = "/customer";
export const CUSTOMER_CRITERIA ="/customer/findByParameters";

export const CUSTOMER_APPT_GET_ALL = "/customerAppointment/getAll";
export const CUSTOMER_APPT_API = "/customerAppointment";
export const CUSTOMER_APPT_CRITERIA ="/customerAppointment/findByParameters"

export const APARTMENT_RESERVATION_GET_ALL = "/apartmentReservation/getAll";
export const APARTMENT_RESERVATION_API = "/apartmentReservation";
export const APARTMENT_RESERVATION_CRITERIA ="/apartmentReservation/findByParameters"