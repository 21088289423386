import {
  ADD_LEAD_FAIL,
  ADD_LEAD_SUCCESS,
  ASSIGN_REPRESENTATIVE_FAIL,
  ASSIGN_REPRESENTATIVE_SUCCESS,
  ASSIGN_SEGMENT_FAIL,
  ASSIGN_SEGMENT_SUCCESS,
  DELETE_LEAD,
  DELETE_LEAD_FAIL,
  DELETE_LEAD_SUCCESS,
  GET_LEAD_BY_ID_FAIL,
  GET_LEAD_BY_ID_SUCCESS,
  GET_LEADS_FAIL,
  GET_LEADS_SUCCESS,
  LEAD_CRITERIA_FAIL,
  LEAD_CRITERIA_SUCCESS,
  TRANSFER_LEAD_DATA_FAIL,
  TRANSFER_LEAD_DATA_SUCCESS,
  UPDATE_LEAD_FAIL,
  UPDATE_LEAD_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  leads: [],
  lead: {},
  error: {},
  processResponse: false,
  reqObject: {}
};

const leads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        leads: action.payload
      };

    case GET_LEADS_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case ADD_LEAD_SUCCESS:
      return {
        ...state,
        leads: [action.payload, ...state.leads],
        processResponse: true
      };

    case ADD_LEAD_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        leads: state.leads.map(lead =>
          lead.id.toString() === action.payload.id.toString()
            ? action.payload
            : lead
        ),
        lead: action.payload,
        processResponse: true
      };

    case UPDATE_LEAD_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case DELETE_LEAD:
      return {
        ...state,
        lead: action.payload
      };

    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        leads: state.leads.filter(
          lead => lead.id.toString() !== action.payload.id.toString()
        ),
        processResponse: true
      };

    case DELETE_LEAD_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LEAD_BY_ID_SUCCESS:
      return {
        ...state,
        lead: action.payload
      };

    case GET_LEAD_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case ASSIGN_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
        leads: state.leads.map(lead => {
          if (action.payload.some(pay => pay.id === lead.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === lead.id) };
            return filtered[0];
          } else {
            return lead;
          }
        }),
        processResponse: true
      };

    case ASSIGN_REPRESENTATIVE_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case ASSIGN_SEGMENT_SUCCESS:
      return {
        ...state,
        leads: state.leads.map(lead => {
          if (action.payload.some(pay => pay.id === lead.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === lead.id) };
            return filtered[0];
          } else {
            return lead;
          }
        }),
        processResponse: true
      };

    case ASSIGN_SEGMENT_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case LEAD_CRITERIA_SUCCESS:
      return {
        ...state,
        leads: action.payload.content,
        page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
        totalCount: action.payload.totalElements,
        totalPages: (action.payload.totalPages - 1)
      };

    case
    LEAD_CRITERIA_FAIL:
      return {
        ...state,
        leads: action.payload.data
      };

    case TRANSFER_LEAD_DATA_SUCCESS:
      return {
        ...state,
        leads: state.leads.map(lead => {
          if (action.payload.some(pay => pay.id === lead.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === lead.id) };
            return filtered[0];
          } else {
            return lead;
          }
        }),
        processResponse: true
      };

    case TRANSFER_LEAD_DATA_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    default:
      return {
        ...state,
        error: {},
        processResponse: false
      };
  }
};

export default leads;
