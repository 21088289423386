import React, {createContext, useEffect, useState} from "react";
import {get} from "../helpers/api_helper";
import * as url from "../helpers/url_helper";
import {isBefore} from "date-fns";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const [authUser, setAuthUser] = useState({});
    const [user, setUser] = useState({});
    const [termsContract, setTermsContract] = useState({});
    const [privacyContract, setPrivacyContract] = useState({});
    const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);
    const [navigateGuard, setNavigateGuard] = useState(false);
    const [loading, setLoading] = useState(false);
    const [navigateLocked, setNavigateLocked] = useState(false);
    const [navigatePayment, setNavigatePayment] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState("");
    const [postponeEnabled, setPostponeEnabled] = useState(false);
    const [userMenu, setUserMenu] = useState([]);
    const [screenList, setScreenList] = useState([]);
    const [screenActionList, setScreenActionList] = useState([]);
    const [dashboard, setDashboard] = useState({});
    const [paymentDueDate, setPaymentDueDate] = useState("");

    useEffect(() => {
        if (authUser && Object.entries(authUser).length > 0) {
            setPasswordChangeRequired(authUser.passwordChangeRequired);
            setTermsContract(authUser.contracts && authUser.contracts[authUser.contracts.findIndex(contract => contract.contractType === "TERMS_CONDITIONS")]);
            setPrivacyContract(authUser.contracts && authUser.contracts[authUser.contracts.findIndex(contract => contract.contractType === "PRIVACY_POLICY")]);
            setNavigateGuard(authUser.passwordChangeRequired || (authUser.contracts && authUser.contracts.length > 0));

            const permittedRoutes = authUser.role.screenList.map(screen => screen.screenUrl);
            permittedRoutes.push("/apartments")
            permittedRoutes.push("/apartment-rooms")
            permittedRoutes.push("/building")
            permittedRoutes.push("/customer")
            permittedRoutes.push("/customer-appointment")
            permittedRoutes.push("/apartment-reservation")
            setUserMenu(permittedRoutes);
            setScreenList(authUser.role.screenList)
            setScreenActionList(authUser.role.screenActionList)
            const filteredDashboard = authUser.role.screenList.filter((screen) => screen.screenUrl === "/doctor-dashboard" || screen.screenUrl === "/dashboard" || screen.screenUrl === "/admin-dashboard")[0];
            setDashboard(filteredDashboard)
            const fetchUserInfo = async () => {
                try {
                    const userProfile = await get(`${url.GET_USER_PROFILE}/${authUser.id}`, authUser);
                    setUser(userProfile);
                } catch (err) {
                    console.log("fetchUserInfoError", err);
                }
            };

            const fetchPaymentInfo = async () => {
                try {
                    const checkPayment = await get(url.UNPAID_PAYMENTS).then(res => {
                        if (res && res.length > 0) {
                            const oldestDueDate = new Date(Math.min(...res.map(e => new Date(e.paymentDueDate))));
                            const isOverDue = isBefore(oldestDueDate, new Date());
                            if (authUser && authUser.role && authUser.role.name === "ROLE_ADMIN") {
                                setNavigatePayment(true);
                                if (isOverDue) {
                                    setPostponeEnabled(false);
                                } else {
                                    setPostponeEnabled(true);
                                    setPaymentDueDate(oldestDueDate)
                                }
                            } else {
                                if (isOverDue) {
                                    setNavigateLocked(true);
                                }
                            }
                        }
                    });
                    setPaymentInfo(checkPayment);
                } catch (err) {
                    console.log("fetchPaymentInfoError", err);
                }
            };

            if (authUser && Object.entries(authUser).length > 0) {
                fetchUserInfo().then(r => console.log("response", r));
                fetchPaymentInfo().then(r => console.log("response", r));
            }
        }
    }, [authUser]);

    useEffect(() => {
        if (authUser && Object.entries(authUser).length === 0) {
            const authStorage = JSON.parse(localStorage.getItem("authUser"));
            setAuthUser(authStorage);
        }
    }, [localStorage]);

    return (
        <AuthContext.Provider value={{
            authUser,
            setAuthUser,
            user,
            termsContract,
            setTermsContract,
            privacyContract,
            setPrivacyContract,
            passwordChangeRequired,
            setPasswordChangeRequired,
            navigateGuard,
            setNavigateGuard,
            loading,
            setLoading,
            paymentInfo,
            setPaymentInfo,
            navigatePayment,
            setNavigatePayment,
            navigateLocked,
            setNavigateLocked,
            postponeEnabled,
            setPostponeEnabled,
            userMenu,
            setUserMenu,
            screenList,
            screenActionList,
            dashboard,
            setDashboard,
            paymentDueDate,
            setScreenList,
            setScreenActionList
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;