import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Input, Label, UncontrolledTooltip } from "reactstrap";

//Import Breadcrumb
//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { withTranslation } from "react-i18next";
import { CreateDate, CreateUser, Note } from "../PatientCol";
import { del, get, post } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import BasicTable from "../../../components/Common/BasicTable";
import WarningModal from "../../../components/Common/WarningModal";
import Notification from "../../../components/Notification";

const PatientNote = props => {

  const dispatch = useDispatch();

  const [note, setNote] = useState("");
  const [patient, setPatient] = useState(props.patient);
  const [custNotes, setCustNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState({});
  const [forceUpdate, setForceUpdate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  const { patients } = useSelector(state => ({
    patients: state.patients.patients
  }));

  useEffect(() => {
    const filtered = patients && patients.filter(patient => patient.id === props.patient.id);
    if (filtered.length === 1) {
      setPatient(filtered[0]);
    }
  }, [dispatch, patients]);

  const noteColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Date")}`,
        accessor: "createDate",
        className: "col-3",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Note")}`,
        accessor: "note",
        className: "col-8",
        Cell: (cellProps) => {
          return <Note {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        className: "col",
        Cell: (cellProps) => {
          return (
            <div className="text-center">
              <Button
                color="danger"
                outline
                onClick={() => {
                  const noteData = cellProps.row.original;
                  onClickDelete(noteData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Button>
            </div>
          );
        }
      }

    ],
    []
  );

  useEffect(() => {
    setForceUpdate(props.forceUpdate);
  }, [props.forceUpdate]);

  useEffect(() => {
    if (patient && ((forceUpdate && patient.id) || patient.id)) {
      get(`${url.GET_PATIENT_NOTE}/${patient.id}`).then(res => setCustNotes(res.data));
      setForceUpdate(false);
    }
  }, [patient && patient.id, forceUpdate, patient]);

  const saveNote = (e) => {
    const createNote = {
      note: note,
      patient: patient
    };
    post(url.ADD_NEW_PATIENT_NOTE, createNote).then((res) => {
      setForceUpdate(true);
    });
    setNote("");
  };

  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };

  const onClickDelete = (note) => {
    setSelectedNote(note);
    setDeleteModal(true);
  };

  const handleDeleteNote = () => {
    if (selectedNote.id) {
      del(`${url.DELETE_PATIENT_NOTE}/${selectedNote.id}`).then(response => {
        if (response.status === 200) {
          setDeleteModal(false);
          setForceUpdate(true);
        } else {
          setShowNotification(true);
          setMessage(response.data.message);
          setNotificationType("Danger");
        }
      });
    }
  };

  const handleCloseNotification = () => {
    setMessage("");
    setShowNotification(false);
  };

  return (
    <React.Fragment>
      <WarningModal
        id="detailDelete"
        show={deleteModal}
        onApproveClick={handleDeleteNote}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleCloseNotification}
        type={notificationType}
      />
      <div className="row mt-3">
        <div className="row mt-3">
          <div className="col-md-10 col-xs-12">
            <div className="mb-3">
              <label>{props.t("Enter A Note")}</label>
              <Input
                id="detailNote"
                name="note"
                type="text"
                onChange={handleChangeNote}
                value={note}
                invalid={note.length < 3}
              />
            </div>
          </div>
          <Col className="col-md-2 col-xs-12">
            <div className="mt-4">
              <Button
                id="detailSaveNote"
                type="button"
                color="success"
                onClick={saveNote}
              >
                {props.t("Add")}
              </Button>
            </div>
          </Col>
        </div>
        <div className="row mt-3">
          <div className="col-xs-12 col-md-12 mt-4">
            <Label className="form-label"><i className="bx bx-copy-alt" />{props.t("Notes")}</Label>
            <BasicTable
              id="custNotes"
              customPageSize={10}
              columns={noteColumns}
              data={custNotes}
              className="table-of-contents"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
PatientNote.propTypes = {
  patient: PropTypes.any,
  forceUpdate: PropTypes.bool
};

export default withTranslation()(PatientNote);
