import React, { useEffect, useState } from "react";
import withRouter from "../../../hooks/withRouter"
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { importCSV as onFetchImportCSV, importExcel as onFetchImportExcel } from "../../../store/import/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { withTranslation } from "react-i18next";
import FormUpload from "../../../components/Common/FormUpload";
import Select from "react-select";
import { getSegments as onGetSegments } from "../../../store/segment/actions";
import { getCategories as onGetCategories } from "../../../store/category/actions";
import { getResources as onGetResources } from "../../../store/resource/actions";
import { getUsersByRole as onGetUsersByRole } from "../../../store/users/actions";
import Notification from "../../../components/Notification";

const LeadImport = props => {

  //meta title
  document.title = `CRM | ${props.t("Lead Import")}`;
  const dispatch = useDispatch();

  const [resource, setResource] = useState({});
  const [segment, setSegment] = useState({});
  const [category, setCategory] = useState({});
  const [salesRepresentative, setSalesRepresentative] = useState({});
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [callbackClose, setCallbackClose] = useState(false);

  const sendFileToUpload = (file) => {
    const request = Object.assign({
      salesRepresentative: salesRepresentative.value,
      segment: segment.value,
      category: category.value,
      resource: resource.value,
      file: file[0].convertedBase64
    });
    let fileSuffix = file[0].name.split(".")[1];
    if (fileSuffix === "xlsx") {
      dispatch(onFetchImportExcel(request));
    } else if (fileSuffix === "csv") {
      dispatch(onFetchImportCSV(request));
    }
  };

  const onChangeInResourceSelect = event => {
    setResource((event));
  };

  const onChangeInCategorySelect = event => {
    setCategory((event));
  };

  const onChangeInSegmentSelect = event => {
    setSegment((event));
  };

  const onChangeInRepresentSelect = event => {
    setSalesRepresentative((event));
  };

  const { importSuccess, error } = useSelector(state => ({
    importSuccess: state.FileImport.importSuccess,
    error: state.FileImport.error
  }));

  useEffect(() => {
    if (importSuccess) {
      setShowNotification(true);
      setMessage("File(s) imported successfully.");
      setNotificationType("Success");
      setCallbackClose(true);
    } else if (Object.entries(error).length>0) {
      setShowNotification(true);
      setMessage(error.message|| "An error occurred!");
      setNotificationType("Danger");
      setCallbackClose(true);
    }
  }, [importSuccess, error]);

  const { categories } = useSelector(state => ({
    categories: state.categories.categories
  }));

  const { segments } = useSelector(state => ({
    segments: state.segments.segments
  }));

  const { resources } = useSelector(state => ({
    resources: state.resources.resources
  }));

  const { users } = useSelector(state => ({
    users: state.User.users
  }));

  const resourceOptions = [];
  resources.map((item) => {
    resourceOptions.push({ label: item.name, value: item });
  });

  const segmentOptions = [];
  segments.map((item) => {
    segmentOptions.push({ label: item.name, value: item });
  });

  const categoryOptions = [];
  categories.map((item) => {
    categoryOptions.push({ label: item.name, value: item });
  });

  const representOptions = [{ label: props.t("Auto assign"), value: {} }];
  users.map((item) => {
    representOptions.push({ label: item.fullName, value: item });
  });

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetSegments());
      setFirstCall(false);
    }
  }, [dispatch, segments]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetCategories());
      setFirstCall(false);
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetResources());
      setFirstCall(false);
    }
  }, [dispatch, resources]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetUsersByRole("ROLE_USER"));
      setFirstCall(false);
    }
  }, [dispatch, users]);


  const handleClose = (val) => {
    setMessage("");
    setShowNotification(val);
    setCallbackClose(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Notification
          message={message}
          show={showNotification}
          callbackShow={handleClose}
          type={notificationType}
        />
        <Container fluid>
          <Breadcrumbs title={props.t("Lead Import")} />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="mt-4 col-md-1 col-sm-6">
                      <a className="btn btn-outline-primary btn-sm"
                         href={process.env.PUBLIC_URL + "/template/CSVTemplate.csv"}
                         download>{props.t("Download Template")}</a>
                    </div>
                    <div className="mb-3 col-md-3 col-sm-6">
                      <Label className="form-label">{props.t("Sales Representative")}</Label>
                      <Select
                        id="represent"
                        value={salesRepresentative}
                        onChange={(e) => {
                          onChangeInRepresentSelect(e);
                        }}
                        options={representOptions}
                      />
                    </div>
                    <div className="mb-3 col-md-3 col-sm-6">
                      <Label className="form-label">{props.t("Segment")}</Label>
                      <Select
                        id="segment"
                        value={segment}
                        onChange={(e) => {
                          onChangeInSegmentSelect(e);
                        }}
                        options={segmentOptions}
                      />
                    </div>
                    <div className="mb-3 col-md-3 col-sm-6">
                      <Label className="form-label">{props.t("Category")}</Label>
                      <Select
                        id="category"
                        value={category}
                        onChange={(e) => {
                          onChangeInCategorySelect(e);
                        }}
                        options={categoryOptions}
                      />
                    </div>
                    <div className="mb-3 col-md-2 col-sm-6">
                      <Label className="form-label">{props.t("Resource")}</Label>
                      <Select
                        id="resource"
                        value={resource}
                        onChange={(e) => onChangeInResourceSelect(e)}
                        options={resourceOptions}
                      />
                    </div>
                  </Row>
                  <FormUpload
                    id="fileForm"
                    sendFile={sendFileToUpload}
                    callbackClose={callbackClose}
                    modal={category.label !== undefined}
                    message={props.t("CATEGORY-MESSAGE",{cat: category.label})}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
LeadImport.propTypes = {
  t: PropTypes.any,
  fetchImportFile: PropTypes.func,
  fetchImportExcel: PropTypes.func,
  fetchImportCSV: PropTypes.func
};

export default withRouter(withTranslation()(LeadImport));
