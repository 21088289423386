import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter"
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


import {
  addNewFacebookForm as onAddNewForm,
  deleteFacebookForm as onDeleteForm,
  getFacebookForms as onGetForms,
  updateFacebookForm as onUpdateForm
} from "store/FacebookForms/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Deletable, FormId, Name, PageId } from "./facebookFormCol";
import { withTranslation } from "react-i18next";
import WarningModal from "../../components/Common/WarningModal";
import Notification from "../../components/Notification";

const FacebookForm = props => {

  //meta title
  document.title = `CRM | ${props.t("Facebook Forms")}`;

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [facebookForm, setFacebookForm] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  // validation
  const validation = {

    values: {
      formId: (facebookForm && facebookForm.formId) || "",
      formName: (facebookForm && facebookForm.formName) || "",
      pageId: (facebookForm && facebookForm.pageId) || "",
      isDeletable: (facebookForm && facebookForm.isDeletable) || ""
    },

    handleSubmit: (values) => {
      if (isEdit) {
        const updateForm = {
          id: facebookForm.id,
          formId: facebookForm.formId,
          formName: values.formName,
          pageId: values.pageId,
          isDeletable: values.isDeletable
        };
        dispatch(onUpdateForm(updateForm));
      } else {
        const newFacebookForm = {
          formName: values["formName"],
          pageId: values["pageId"],
          isDeletable: values["isDeletable"]
        };
        // save new form
        dispatch(onAddNewForm(newFacebookForm));
      }
      toggle();
    }
  };

  const handleFormClick = arg => {
    const form = arg.original;

    setFacebookForm({
      id: form.id,
      formId: form.formId,
      formName: form.formName,
      pageId: form.pageId,
      isDeletable: form.isDeletable
    });

    setIsEdit(true);
    toggle();
  };

  const handleChange = (e) => {
    setFacebookForm({
      ...facebookForm,
      [e.target.name]: e.target.value
    });
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "formName",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Form ID")}`,
        Header: "ID",
        accessor: "formId",
        filterable: true,
        Cell: (cellProps) => {
          return <FormId {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Page ID")}`,
        Header: "Kod",
        accessor: "pageId",
        filterable: true,
        Cell: (cellProps) => {
          return <PageId {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const formData = cellProps.row.original;
                  onClickDelete(formData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setFacebookForm(null);
    } else {
      setModal(true);
    }
  };


  //delete form
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (facebookForm) => {
    setFacebookForm(facebookForm);
    setDeleteModal(true);
  };

  const handleDeleteForm = () => {
    if (facebookForm.id) {
      dispatch(onDeleteForm(facebookForm.id));
      setDeleteModal(false);
    }
  };

  const { facebookForms, error } = useSelector(state => ({
    facebookForms: state.FacebookForms.facebookForms,
    error: state.FacebookForms.error
  }));

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetForms());
      setFirstCall(false);
    }
  }, [dispatch, facebookForms]);

  const handleFormClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetForms());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteForm}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Forms")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={facebookForms}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleFormClicks}
                    handleRowClick={handleFormClick}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="formName"
                                type="text"
                                onChange={handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.formName || ""}
                                required={true}
                                invalid={!validation.values.formName}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Form ID")}</Label>
                              <Input
                                name="formId"
                                type="text"
                                disabled={true}
                                value={validation.values.formId || ""}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Page ID")}</Label>
                              <Input
                                name="pageId"
                                type="text"
                                disabled={true}
                                value={validation.values.pageId || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
FacebookForm.propTypes = {
  forms: PropTypes.array,
  onGetForms: PropTypes.func,
  onAddNewForm: PropTypes.func,
  onDeleteForm: PropTypes.func,
  onUpdateForm: PropTypes.func
};

export default withRouter(withTranslation()(FacebookForm));
