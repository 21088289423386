import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from "reactstrap";


import {Description, Name} from "./buildingCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WarningModal from "components/Common/WarningModal";

import {
    addNewBuilding as onAddNewBuilding,
    deleteBuilding as onDeleteBuilding,
    getBuildings as onGetBuildings,
    updateBuilding as onUpdateBuilding
} from "store/building/actions";

//redux
import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import withRouter from "../../hooks/withRouter";
import Notification from "../../components/Notification";

const Building = props => {

    //meta title
    document.title = `CRM | ${props.t("Buildings")}`;

    const initialBuilding = {
        blockName: "",
        projectName:"",
        numberOfApartments: 0,
        address: "",
        constructionYear: 0
    }

    const dispatch = useDispatch();
    const [deleteBuilding, setDeleteBuilding] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [building, setBuilding] = useState(initialBuilding);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");

    const {buildings, error} = useSelector(state => ({
        buildings: state.Building.buildings,
        error: state.Building.error
    }));

    useEffect(() => {
        dispatch(onGetBuildings());
    }, []);


    const handleRefresh = () => {
        dispatch(onGetBuildings());
    };

    const columns = useMemo(
        () => [
            {
                HeaderLabel: `${props.t("Block Name")}`,
                accessor: "blockName",
                filterable: true,
                Cell: cellProps => {
                    return <Name {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Project Name")}`,
                accessor: "projectName",
                filterable: true,
                Cell: cellProps => {
                    return <Name {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Number Of Apartments")}`,
                accessor: "numberOfApartments",
                filterable: true,
                Cell: cellProps => {
                    return <Name {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Construction Year")}`,
                accessor: "constructionYear",
                filterable: true,
                Cell: cellProps => {
                    return <Name {...cellProps} />;
                }
            }, {
                HeaderLabel: `${props.t("Address")}`,
                accessor: "address",
                filterable: true,
                Cell: cellProps => {
                    return <Description {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Visible")}`,
                accessor: "visible",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            {props.t(`${cellProps.row.original.visible === false ? props.t("NO") : props.t("YES")}`)}
                        </div>
                    );
                }
            },
            {
                HeaderLabel: `${props.t("Action")}`,
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-warning"
                                onClick={() => {
                                    setBuilding(cellProps.row.original),
                                        handleBuildingClick(cellProps.row);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip"/>
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    {props.t("Edit")}
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const buildingData = cellProps.row.original;
                                    onClickDelete(buildingData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip"/>
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    {props.t("Delete")}
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            }
        ],
        []
    );

    const toggle = () => {
        if (modal) {
            setBuilding(initialBuilding);
            setModal(false);
            setIsEdit(false);
        } else {
            setModal(true);
        }
    };

    const handleBuildingClick = arg => {
        const building = arg.original;
        setIsEdit(true);
        setBuilding({
            blockName: building.blockName,
            numberOfApartments: building.numberOfApartments,
            projectName: building.projectName,
            address: building.address,
            constructionYear: building.address
        });
        toggle();
    };

    //delete lead
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = building => {
        setDeleteBuilding(building);
        setDeleteModal(true);
    };

    const handleDeleteBuilding = () => {
        dispatch(onDeleteBuilding(deleteBuilding.id));
        setDeleteModal(false);
    };

    const handleChange = (e) => {
        setBuilding({
            ...building,
            [e.target.name]: e.target.value
        });
    };

    const handleClose = () => {
        setMessage("");
        setShowNotification(false);
    };

    const saveBuilding = () => {
        if (isEdit) {
            dispatch(onUpdateBuilding(building));
        } else {
            dispatch(onAddNewBuilding(building));
        }
        toggle();
    };

    useEffect(() => {
        if (Object.entries(error).length > 0) {
            setMessage(error.message);
            setShowNotification(true);
            setNotificationType("Danger");
        }
    }, [error]);

    return (
        <React.Fragment>
            <WarningModal
                show={deleteModal}
                onApproveClick={handleDeleteBuilding}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Notification
                message={message}
                show={showNotification}
                callbackShow={handleClose}
                type={notificationType}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={props.t("Buildings")}/>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        refreshButton={true}
                                        handleRefreshClick={handleRefresh}
                                        columns={columns}
                                        data={buildings}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        handleAddClick={toggle}
                                        customPageSize={25}
                                        className="custom-header-css"
                                    />
                                    <Modal isOpen={modal} toggle={toggle}>
                                        <ModalHeader toggle={toggle} tag="h4">
                                            {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                                        </ModalHeader>
                                        <ModalBody>
                                            <Row form="true">
                                                <Col xs={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("Project Name")}</Label>
                                                        <Input
                                                            name="projectName"
                                                            type="text"
                                                            onChange={handleChange}
                                                            value={building.projectName || ""}
                                                            invalid={!building.projectName}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("Block Name")}</Label>
                                                        <Input
                                                            name="blockName"
                                                            type="text"
                                                            onChange={handleChange}
                                                            value={building.blockName || ""}
                                                            invalid={!building.blockName}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("Number Of Apartments")}</Label>
                                                        <Input
                                                            name="numberOfApartments"
                                                            type="text"
                                                            onChange={handleChange}
                                                            value={building.numberOfApartments || ""}
                                                            invalid={!building.numberOfApartments}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("Construction Year")}</Label>
                                                        <Input
                                                            name="constructionYear"
                                                            type="text"
                                                            onChange={handleChange}
                                                            value={building.constructionYear || ""}
                                                            invalid={!building.constructionYear}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("Address")}</Label>
                                                        <Input
                                                            name="address"
                                                            type="text"
                                                            onChange={handleChange}
                                                            value={building.address || ""}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="text-end">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success save-building"
                                                            onClick={saveBuilding}
                                                        >
                                                            {props.t("Save")}
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(Building));
