import {
    ADD_APARTMENT_FAIL,
    ADD_APARTMENT_SUCCESS,
    APARTMENT_CRITERIA_FAIL,
    APARTMENT_CRITERIA_SUCCESS, APARTMENT_FIND_FILTERS, APARTMENT_FIND_FILTERS_FAIL, APARTMENT_FIND_FILTERS_SUCCESS,
    DELETE_APARTMENT_FAIL,
    DELETE_APARTMENT_SUCCESS,
    GET_APARTMENTS_FAIL,
    GET_APARTMENTS_SUCCESS,
    UPDATE_APARTMENT_FAIL,
    UPDATE_APARTMENT_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    apartments: [],
    apartmentFilters:{},
    error: {},
    page: 0,
    totalCount: 0,
    totalPages: 0
};

const Apartment = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APARTMENTS_SUCCESS:
            return {
                ...state,
                apartments: action.payload,
            };

        case GET_APARTMENTS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_APARTMENT_SUCCESS:
            return {
                ...state,
                apartments: [...state.apartments, action.payload],
            };

        case ADD_APARTMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_APARTMENT_SUCCESS:
            return {
                ...state,
                apartments: state.apartments.map(apartment =>
                    apartment.id.toString() === action.payload.id.toString()
                        ? { apartment, ...action.payload }
                        : apartment
                ),
            };

        case UPDATE_APARTMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_APARTMENT_SUCCESS:
            return {
                ...state,
                apartments: state.apartments.filter(
                    apartment => apartment.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_APARTMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case APARTMENT_CRITERIA_SUCCESS:
            return {
                ...state,
                apartments: action.payload.content,
                page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
                totalCount: action.payload.totalElements,
                totalPages: (action.payload.totalPages - 1)
            };

        case APARTMENT_CRITERIA_FAIL:
            return {
                ...state,
                apartments: action.payload.data
            };

        case APARTMENT_FIND_FILTERS_SUCCESS:
            return {
                ...state,
                apartmentFilters: action.payload,
            };

        case APARTMENT_FIND_FILTERS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return {
                ...state,
                error: {}
            };
    }
};

export default Apartment;
