import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";

import { withTranslation } from "react-i18next";
import { CreateDate, ReminderDesc } from "../ApartmentCol";
import { formatDate } from "../../../common/commonFunctions";
import { get } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import BasicTable from "../../../components/Common/BasicTable";
import { addNewReminder } from "../../../store/reminder/actions";
import { useDispatch } from "react-redux";
import CustomDatePicker from "../../../components/Common/CustomDatePicker";

const ApartmentReminder = props => {
  const dispatch = useDispatch();

  const [reminderDate, setReminderDate] = useState(null);
  const [reminderDescription, setReminderDescription] = useState("");
  const [selectedReminder, setSelectedReminder] = useState(false);
  const [custReminder, setCustReminder] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [lead, setLead] = useState({});

  const reminderColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Date")}`,
        Header: "Tarih",
        accessor: "reminderDate",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <ReminderDesc {...cellProps} />;
        }
      }

    ],
    []
  );

  useEffect(() => {
    setLead(props.lead);
  }, [props.lead]);

  const saveReminder = (e) => {
    const createReminder = {
      reminderDate: formatDate(reminderDate),
      description: reminderDescription,
      lead: props.lead
    };

    dispatch(addNewReminder(createReminder));
    setForceUpdate(true);
    setReminderDate(null);
    setReminderDescription("");
    setSelectedReminder(false);
  };

  useEffect(() => {
    if (forceUpdate && lead.id || lead.id) {
      get(`${url.GET_LEAD_REMINDER}/${lead.id}`).then(res => setCustReminder(res.data));
      setForceUpdate(false);
    }
  }, [forceUpdate, lead.id]);

  const handleChangeReminderDate = (e) => {
    setSelectedReminder(true);
    setReminderDate(e);
  };

  const handleChangeReminderDescription = (e) => {
    setReminderDescription(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="mt-3">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="col">
              <label>{props.t("Reminder Time")}</label>
            </div>
          <div className="col">
            <CustomDatePicker
                id="reminderDate"
                selected={reminderDate}
                callback={handleChangeReminderDate}
                showTimeSelect={true}
                showTimeSelectOnly={false}
                showTimeInput={true}
            />
          </div>
          </div>
          <div className="col-xs-12 col-md-7">
            <label>{props.t("Description")}</label>
            <Input
              id="reminderDesc"
              value={reminderDescription}
              onChange={handleChangeReminderDescription}
            />
          </div>
          <div className="col">
            <div className="mt-4">
              <Button
                id="saveReminder"
                type="button"
                color="success"
                onClick={saveReminder}
                disabled={!selectedReminder}
              >
                {props.t("Add")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        {custReminder.length > 0 ? (
          <BasicTable
            id="custReminder"
            customPageSize={10}
            columns={reminderColumns}
            data={custReminder}
            className="custom-header-basic-css"
          />) : null}
      </div>
    </React.Fragment>
  );
};
ApartmentReminder.propTypes = {
  lead: PropTypes.any
};

export default withTranslation()(ApartmentReminder);
