import React, {useEffect, useState} from "react";
import {Col, Collapse, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import withRouter from "../../../hooks/withRouter";
import {withTranslation} from "react-i18next";
import Notification from "../../../components/Notification";
import ApartmentReservation from "./ApartmentReservation";
import ApartmentReminder from "./ApartmentReminder";
import ApartmentNote from "./ApartmentNote";
import {useDispatch, useSelector} from "react-redux";
import {updateApartment as onUpdateApartment} from "../../../store/apartment/actions";
import {isEmpty} from "lodash";
import {getCustomers} from "../../../store/customer/actions";

const isAgent = process.env.REACT_APP_IS_AGENT === "TRUE";
const ApartmentDetail = props => {
    const dispatch = useDispatch();

    const [apartment, setApartment] = useState(props.apartment);
    const [apartmentRoomOptions, setApartmentRoomOptions] = useState(props.roomsOptions);
    const [buildingOptions, setBuildingOptions] = useState(props.buildingOptions);
    const [statusOptions, setStatusOptions] = useState(props.statusOptions);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [facade, setFacade] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [reminderOpen, setIsReminderOpen] = useState(false);
    const [apptOpen, setIsApptOpen] = useState(false);
    const [status, setStatus] = useState(null);
    const [building, setBuilding] = useState(null);
    const [numberOfRooms, setNumberOfRooms] = useState(null);

    const toggleReminder = () => setIsReminderOpen(!reminderOpen);
    const handleOpenModal = () => setIsModalOpen(!isModalOpen);
    const toggleAppt = () => setIsApptOpen(!apptOpen);

    useEffect(() => {
        setApartment(props.apartment);
    }, [props.apartment.id]);

    const {error, apartments} = useSelector(state => ({
        error: state.Apartment.error,
        apartments: state.Apartment.apartments
    }));

    const handleChange = (e) => {
        const regExpMail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const regExpText = /^(^$)|^[a-zA-Z çşğıÇŞĞIİÜüÖö]+$/;
        const regExpPhone = /^\d+$/;

        switch (e.target.type) {
            case "textarea":
                setApartment({
                    ...apartment,
                    [e.target.name]: e.target.value
                });
                break;
            case "tel":
                if (regExpPhone.test(e.target.value.replaceAll("+", ""))) {
                    setApartment({
                        ...apartment,
                        ["phoneNumber"]: e.target.value
                    });
                }
                break;
            default :
                setApartment({
                    ...apartment,
                    [e.target.name]: e.target.value
                });
                break;
        }

    };

    function handleFacade(e) {
        setFacade(e);
        setApartment({
            ...apartment,
            ["facade"]: e.value
        });
    }

    const updateApartment = () => {
        const updateApartment = Object.assign({
            ...apartment,
            id: apartment.id,
            apartmentNumber: apartment.apartmentNumber,
            floorNumber: apartment.floorNumber,
            grossSquareMeters: apartment.grossSquareMeters,
            netSquareMeters: apartment.netSquareMeters,
            numberOfRooms: apartment.numberOfRooms,
            balconyCount: apartment.balconyCount,
            bathroomCount: apartment.bathroomCount,
            rental: apartment.rental,
            sale: apartment.sale,
            rentalPrice: apartment.rentalPrice,
            salePrice: apartment.salePrice,
            description: apartment.description,
            status: apartment.status,
            building: apartment.building
        });
        // update apartment
        dispatch(onUpdateApartment(updateApartment));
    };

    useEffect(() => {
        if (!isEmpty(error) && Object.entries(error).length > 0) {
            setShowNotification(true);
            setMessage(error.message);
            setNotificationType("Danger");
        }
    }, [error]);

    const onChangeInStatusSelect = event => {
        setApartment({
            ...apartment,
            ["status"]: event !== null ? event.value : null
        });
        setStatus((event));
    };

    const onChangeInBuildingSelect = event => {
        setApartment({
            ...apartment,
            ["building"]: event !== null ? event.value : null
        });
        setBuilding((event));
    };

    const onChangeInNumberOfRoomsSelect = event => {
        setApartment({
            ...apartment,
            ["numberOfRooms"]: event !== null ? event.value : null
        });
        setNumberOfRooms((event));
    };

    const handleClose = () => {
        setMessage("");
        setShowNotification(false);
    };

    return (
        <React.Fragment>
            <Notification
                message={message}
                show={showNotification}
                callbackShow={handleClose}
                type={notificationType}
            />
            <div className="container-fluid">
                <div className="mt-2">
                    <Col className="col-12">
                        <div className="mb-3">
                            <Label
                                className="form-label">{props.t("Description")}</Label>
                            <Input
                                id="description"
                                name="description"
                                type="textarea"
                                rows="1"
                                onChange={handleChange}
                                value={apartment && apartment.description || ""}
                            />
                        </div>
                        <Row className="row-cols-3">
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Status")}</Label>
                                <Select
                                    id="statusSelect"
                                    value={status}
                                    onChange={(e) => {
                                        onChangeInStatusSelect(e);
                                    }}
                                    options={statusOptions}
                                    isClearable={true}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Building")}</Label>
                                <Select
                                    id="building"
                                    value={building}
                                    onChange={(e) => {
                                        onChangeInBuildingSelect(e);
                                    }}
                                    options={buildingOptions}
                                    isClearable={true}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Facade")}</Label>
                                <Select
                                    id="facade"
                                    //isMulti={true}
                                    value={facade}
                                    onChange={(e) => handleFacade(e)}
                                    options={[
                                        {label: props.t("NORTH"), value: "NORTH"},
                                        {label: props.t("SOUTH"), value: "SOUTH"},
                                        {label: props.t("EAST"), value: "EAST"},
                                        {label: props.t("WEST"), value: "WEST"},
                                    ]}
                                    isClearable={true}
                                />
                            </div>
                        </Row>

                        <Row className="row-cols-3">
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Number Of Room")}</Label>
                                <Select
                                    id="numberOfRooms"
                                    value={numberOfRooms}
                                    onChange={(e) => {
                                        onChangeInNumberOfRoomsSelect(e);
                                    }}
                                    options={apartmentRoomOptions}
                                    isClearable={true}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Floor Number")}</Label>
                                <Input
                                    id="floorNumber"
                                    name="floorNumber"
                                    type="text"
                                    onChange={handleChange}
                                    value={apartment && apartment.floorNumber || ""}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Apartment Number")}</Label>
                                <Input
                                    id="apartmentNumber"
                                    name="apartmentNumber"
                                    type="text"
                                    onChange={handleChange}
                                    value={apartment && apartment.apartmentNumber || ""}
                                />
                            </div>
                        </Row>
                        <Row className="row-cols-4">
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Balcony Count")}</Label>
                                <Input
                                    id="balconyCount"
                                    name="balconyCount"
                                    type="text"
                                    onChange={handleChange}
                                    value={apartment && apartment.balconyCount || ""}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Bathroom Count")}</Label>
                                <Input
                                    id="bathroomCount"
                                    name="bathroomCount"
                                    type="text"
                                    onChange={handleChange}
                                    value={apartment && apartment.bathroomCount || ""}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Gross Square Meters")}</Label>
                                <Input
                                    id="grossSquareMeters"
                                    name="grossSquareMeters"
                                    type="text"
                                    onChange={handleChange}
                                    value={apartment && apartment.grossSquareMeters || ""}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Net Square Meters")}</Label>
                                <Input
                                    id="netSquareMeters"
                                    name="netSquareMeters"
                                    type="text"
                                    onChange={handleChange}
                                    value={apartment && apartment.netSquareMeters || ""}
                                />
                            </div>
                        </Row>
                        <Row className="row-cols-4">
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Rental")}</Label>
                                <Select
                                    id="rental"
                                    value={{
                                        label: apartment && apartment.rental === true ? props.t("YES") : props.t("NO"),
                                        value: apartment && apartment.rental
                                    }}
                                    onChange={(e) => {
                                        setApartment({
                                            ...apartment,
                                            rental: e.value
                                        });
                                    }}
                                    options={[{
                                        label: props.t("YES"),
                                        value: true
                                    }, {label: props.t("NO"), value: false}]}
                                    isClearable={true}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Sale")}</Label>
                                <Select
                                    id="sale"
                                    value={{
                                        label: apartment && apartment.sale === true ? props.t("YES") : props.t("NO"),
                                        value: apartment && apartment.sale
                                    }}
                                    onChange={(e) => {
                                        setApartment({
                                            ...apartment,
                                            sale: e.value
                                        });
                                    }}
                                    options={[{
                                        label: props.t("YES"),
                                        value: true
                                    }, {label: props.t("NO"), value: false}]}
                                    isClearable={true}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Rental Price")}</Label>
                                <Input
                                    id="rentalPrice"
                                    name="rentalPrice"
                                    type="text"
                                    onChange={handleChange}
                                    value={apartment && apartment.rentalPrice || ""}
                                />
                            </div>
                            <div className="mb-3">
                                <Label
                                    className="form-label">{props.t("Sale Price")}</Label>
                                <Input
                                    id="salePrice"
                                    name="salePrice"
                                    type="text"
                                    onChange={handleChange}
                                    value={apartment && apartment.salePrice || ""}
                                />
                            </div>
                        </Row>
                    </Col>
                    <div className="text-end mb-3">
                        <button
                            id="detailSave"
                            className="btn btn-success save-apartment"
                            onClick={updateApartment}
                        >
                            {props.t("Save Changes")}
                        </button>
                    </div>
                </div>
                <hr/>
                <div className="row mt-2">
                    <ApartmentNote
                        apartment={apartment}
                    />
                </div>
                <hr/>
                <Row className="p-2">
                    <div className="custom-accordion">
                        <Link
                            className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
                            onClick={toggleAppt}
                            to="#"
                        >
                            <i className="mdi mdi-calendar-account font-size-20 text-secondary"></i>{" "}
                            {props.t("Reservations")}
                            <i
                                className={
                                    isOpen
                                        ? "mdi mdi-chevron-up accor-down-icon"
                                        : "mdi mdi-chevron-down accor-down-icon"
                                }
                            />
                        </Link>
                        <Collapse isOpen={apptOpen}>
                            <div className="card border-1 shadow-none mb-0">
                                {apptOpen ? (
                                    <div className="row mt-2">
                                        <ApartmentReservation
                                            apartment={apartment}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </Collapse>
                    </div>
                </Row>
                <hr/>
            </div>
        </React.Fragment>
    );
};
ApartmentDetail.propTypes = {
    id: PropTypes.any.isRequired,
    t: PropTypes.any,
    apartment: PropTypes.any,
    roomsOptions: PropTypes.any,
    buildingOptions: PropTypes.any,
    statusOptions: PropTypes.any,
    tabIndex: PropTypes.any
};

export default withRouter(withTranslation()(ApartmentDetail));
